@import 'react-notifications/lib/notifications.css';

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.925rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  color: tomato !important;
  padding-left: 5px;
}

.white {
  color: #fff;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link {
  padding: 0.8125rem 1.937rem 0.8125rem 1rem !important;
  color: #6C7383;
  border-radius: 8px
}

.onhover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, .06) !important;
}

.mr-7 {
  margin-right: 7px;
}

.mb-7 {
  margin-bottom: 7px;
}

.d-contain {
  width: 100%;
  display: table;
}

.d-row {
  display: table-row;
}

.d-cell {
  display: table-cell;
}

.mt-20 {
  margin-top: 20px;
}

.w-80 {
  width: 80%;
}

.nav-sidebar>.nav-item:hover>.nav-link,
.nav-sidebar>.nav-item>.nav-link:focus {
  background-color: #f8f9fa !important;
  color: #2c2c2c !important;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #4B49AC !important;
}

.main-sidebar {
  background-color: #fff;
}

.user-panel .info {
  color: #6C7383;
  margin: 7px;
  font-size: 17px;
}

.brand-text {
  font-weight: 700 !important;
  color: #6C7383;
}

.user-panel,
.brand-link {
  border-bottom: 1px solid #d5d6d7 !important;
}

.main-footer {
  font-size: 14px !important;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.w-25 {
  width: 25%;
}

.ml-20 {
  margin-left: 25px;
}

.rmv-btn {
  padding: 20px;
  border: 2px #bababa solid;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  left: 38%;
  top: 38%;
  margin: 0;
}

.rmv-btn i {
  color: #d91717;
}

.regular-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px !important;
  border-radius: 3px;
  max-height: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.regular-checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
}

.bs-stepper-header {
  overflow-x: auto;
}

.lh-25 {
  line-height: 25px;
}

.custom-control-button::before,
.custom-file-button {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-file-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  z-index: 10;
  height: unset;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: none;
}

.custom-file-input {
  display: none;
}

@media (max-width: 520px) {
  .bs-stepper-header {
    margin: 0 0px !important;
  }

  .bs-stepper .step-trigger {
    padding: 7px !important;
  }

  .bs-stepper-label {
    font-size: 12px;
  }
}

.radio-group label {
  margin-right: 30px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #6e7ea1;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}






.vtab {
  float: left;

  width: 30%;
  height: 300px;
}

/* Style the buttons inside the vtab */
.vtab-button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Change background color of buttons on hover */
.vtab-button:hover {
  background-color: #ddd;
}

/* Create an active/current "vtab button" class */
.vtab-button.active {
  background-color: #ccc;
}

.vtab-content {
  border: 1px solid #ccc;
  padding: 25px 10px;
  width: 100%;
}

.vtab-questions {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Style the vtab content */
.vtabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 300px;
}

.mh-150 {
  min-height: 150px !important;
  margin: 15px 0;
}

.vtab-footer {
  padding: 10px;
  float: right;
}

.vtab-button-container {
  height: 500px;
  border: 2px solid #2c2c2c;
  overflow-y: auto;
  overflow-x: hidden;
}

.vtab-footer button {
  background-color: #4B49AC;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.d-sm-below {
  display: none;
}

.d-sm-above {
  display: block;
}

@media screen and (max-width: 457px) {
  .d-sm-below {
    display: block;
  }

  .d-sm-above {
    display: none;
  }

  .qsection {
    width: 25% !important;
  }

  .vtab-button-container {
    height: 800px;
  }

  .psection {
    width: 75% !important;
  }

  .rmv-btn {
    padding: 15px;
    left: 38%;
    top: 30%;
  }

}

.custom-file-button {
  width: 60%;
  margin: auto 20%;
}

.card-title {
  font-size: 1.25rem !important;
}